import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../components/Loading';
import useSelfServeOrganization from '../../hooks/useSelfServeOrganization';
import { SuccessGreen } from '../../styles';

const { Text, Title } = Typography;

const StyledFlex = styled(Flex)`
  margin-top: calc(100vh * 0.05);
`;

const StyledCheckCircle = styled(CheckCircleFilled)`
  color: ${SuccessGreen};
  font-size: 70px;
`;

const StyledText = styled(Text)`
  text-align: center;
`;

function UploadSuccessful() {
  const navigate = useNavigate();
  const { isLoading: organizationLoading, selfServeEnabled } = useSelfServeOrganization();

  if (organizationLoading) {
    return <Loading />;
  }

  return (
    <StyledFlex vertical align="center" gap={16}>
      <Flex vertical align="center">
        <StyledCheckCircle />
        <Title level={3}>Upload successful</Title>
      </Flex>
      <Flex vertical align="center" gap={1}>
        {!selfServeEnabled && (
          <>
            <StyledText type="secondary">We will begin analyzing your responses.</StyledText>
            <StyledText type="secondary">
              Customer Success will reach out within one business day.
            </StyledText>
          </>
        )}
      </Flex>
      <Button type="primary" onClick={() => navigate('/')}>
        Go to Survey List
      </Button>
    </StyledFlex>
  );
}

export default UploadSuccessful;
