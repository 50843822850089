import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Badge, Button, Dropdown, Flex, Space, Tabs, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useArchiveSurvey, useSurveyList } from '../../api/Surveys';
import InviteButton from '../../components/InviteButton';
import InviteMemberModal from '../../components/InviteMemberModal';
import Loading from '../../components/Loading';
import SearchableTable from '../../components/SearchableTable';
import { MatomoEvent, SurveyListTabs, SurveyStatus, UserRoleType } from '../../constants';
import useMatomo from '../../hooks/useMatomo';
import useRoles from '../../hooks/useRoles';
import useSelfServeOrganization from '../../hooks/useSelfServeOrganization';
import useSuperuser from '../../hooks/useSuperuser';
import EditSurveyDrawer from './EditSurveyDrawer';
import SurveyDetailsModal from './SurveyDetailsModal';

const { Title } = Typography;

const StyledDiv = styled.div`
  padding: 0 24px 24px;
`;

function SurveyList() {
  const roles = useRoles();
  const isSuperuser = useSuperuser();
  const { isLoading: organizationLoading, selfServeEnabled } = useSelfServeOrganization();
  const { trackEvent, trackPageView } = useMatomo();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [activeTab, setActiveTab] = useState(SurveyListTabs.SURVEYS);

  const { data: surveys, isLoading: surveysLoading } = useQuery(['surveys'], useSurveyList(), {
    select: (data) => {
      const sortedData = data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      return {
        ...data,
        data: isSuperuser ? sortedData.filter((s) => !s.is_demo) : sortedData,
      };
    },
  });

  const { mutate: archiveSurvey, isLoading: archiveSurveyLoading } =
    useMutation(useArchiveSurvey());

  const tabData = [
    {
      key: SurveyListTabs.SURVEYS,
      label: SurveyListTabs.SURVEYS,
    },
    {
      key: SurveyListTabs.ARCHIVE,
      label: SurveyListTabs.ARCHIVE,
    },
  ];

  const columns = [
    {
      title: `Survey (${
        surveys?.data.filter((s) =>
          activeTab === SurveyListTabs.SURVEYS ? !s.is_archived : s.is_archived,
        ).length
      })`,
      dataIndex: 'name',
      render: (name, record) => {
        // self-serve admins and editors have a link to the theme engine portal
        if (
          selfServeEnabled &&
          (roles.includes(UserRoleType.ADMINISTRATOR) || roles.includes(UserRoleType.EDITOR))
        ) {
          return <Link to={`/surveys/${JSON.stringify(record.id)}/portal`}>{name}</Link>;
        }
        // self-serve viewers have a link to the dashboard
        // all non-self-serve user have a link to the dashboard if the survey has been published
        if (
          (selfServeEnabled && roles.includes(UserRoleType.VIEWER)) ||
          record.survey_status === SurveyStatus.PUBLISHED
        ) {
          return <Link to={`/surveys/${JSON.stringify(record.id)}`}>{name}</Link>;
        }
        // non-self-serve users have no link if the survey hasn't been published
        return name;
      },
    },
  ];

  if (isSuperuser) {
    columns.push({
      title: 'Client',
      dataIndex: 'organization_name',
    });

    columns.push({
      title: 'Client type',
      dataIndex: 'organization_type',
    });
  }

  columns.push({
    title: 'Status',
    dataIndex: 'survey_status',
    render: (status) => {
      if (status !== SurveyStatus.PUBLISHED) {
        return (
          <Space>
            <Badge color="orange" />
            {
              // non-guest users in self-serve orgs see "Published/Unpublished";
              // guest users in self-serve orgs and users in non-self-serve orgs see "Ready to view"/"In progress"
            }
            {selfServeEnabled && !roles.includes(UserRoleType.CLIENT)
              ? 'Unpublished'
              : 'In progress'}
          </Space>
        );
      }
      return (
        <Space>
          <Badge color="green" />
          {selfServeEnabled && !roles.includes(UserRoleType.CLIENT) ? 'Published' : 'Ready to view'}
        </Space>
      );
    },
  });

  columns.push({
    key: 'action',
    render: (_, record) => {
      const menuItems = [];
      if (roles.includes(UserRoleType.ADMINISTRATOR) || roles.includes(UserRoleType.EDITOR)) {
        menuItems.push({
          key: 'Edit',
          label: 'Edit details',
          onClick: () => {
            setSelectedSurvey(record);
          },
        });
        menuItems.push({
          key: 'Archive',
          label: record.is_archived ? 'Unarchive survey' : 'Archive survey',
          onClick: () => {
            archiveSurvey(
              { surveyId: record.id, data: { is_archived: !record.is_archived } },
              {
                onSuccess: () => {
                  queryClient.setQueryData(['surveys'], (prevSurveys) => {
                    const updatedSurveys = prevSurveys.data.map((s) =>
                      s.id === record.id ? { ...s, is_archived: !record.is_archived } : s,
                    );
                    return { ...prevSurveys, data: updatedSurveys };
                  });
                  message.success(record.is_archived ? 'Survey unarchived' : 'Survey archived');
                },
                onError: () => {
                  message.error('Something went wrong');
                },
              },
            );
          },
        });
      } else {
        menuItems.push({
          key: 'Details',
          label: 'View details',
          onClick: () => {
            setSelectedSurvey(record);
          },
        });
      }

      return (
        <Dropdown menu={{ items: menuItems }}>
          <Button type="text">
            <MoreOutlined />
          </Button>
        </Dropdown>
      );
    },
  });

  useEffect(() => {
    trackPageView('Survey List');
  }, [trackPageView]);

  const tabBarOperations = (
    <>
      {roles.includes(UserRoleType.ADMINISTRATOR) && (
        <InviteButton onClick={() => setShowInviteModal(true)} />
      )}
      {(roles.includes(UserRoleType.ADMINISTRATOR) || roles.includes(UserRoleType.EDITOR)) && (
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            trackEvent(MatomoEvent.OPEN_SURVEY_UPLOAD_FORM);
            navigate('/surveys/upload');
          }}
        >
          New upload
        </Button>
      )}
    </>
  );

  if (organizationLoading || surveysLoading) {
    return <Loading />;
  }

  return (
    <StyledDiv>
      <Title level={4}>Survey List</Title>
      <Flex vertical gap={16}>
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={tabData}
          tabBarExtraContent={tabBarOperations}
        />
        <SearchableTable
          columns={columns}
          baseData={surveys.data.filter((s) =>
            activeTab === SurveyListTabs.SURVEYS ? !s.is_archived : s.is_archived,
          )}
          pagination={{
            defaultPageSize: 30,
            pageSizeOptions: [10, 20, 30, 50, 100],
          }}
          searchPlaceholder="Search surveys..."
          rowKey="id"
          loading={archiveSurveyLoading}
          searchColumns={isSuperuser ? ['organization_name', 'name'] : ['name']}
        />
      </Flex>
      <InviteMemberModal closeModal={() => setShowInviteModal(false)} visible={showInviteModal} />
      {roles.includes(UserRoleType.ADMINISTRATOR) || roles.includes(UserRoleType.EDITOR) ? (
        <EditSurveyDrawer survey={selectedSurvey} setSelectedSurvey={setSelectedSurvey} />
      ) : (
        <SurveyDetailsModal closeModal={() => setSelectedSurvey(null)} survey={selectedSurvey} />
      )}
    </StyledDiv>
  );
}

export default SurveyList;
