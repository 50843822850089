import { InfoCircleOutlined } from '@ant-design/icons';
import { useDroppable } from '@dnd-kit/core';
import { Button, Card, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ThemeOrganizationContainer } from '../../../constants';
import { BluePrimary } from '../../../styles';

const { Text } = Typography;

const StyledDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 250px;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-grow: 1;
  border-style: dashed;
  border-color: ${(props) => (props.$isOver ? BluePrimary : '#d9d9d9')};
  margin-top: 40px;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 8px;
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  .ant-typography {
    :hover {
      color: ${BluePrimary};
    }
    color: ${(props) => (props.$isOver ? BluePrimary : 'rgba(0, 0, 0, 0.85)')};
    white-space: pre-line;
  }
`;

const StyledRow = styled(Row)`
  flex-grow: 1;
`;

const Size12Text = styled(Text)`
  font-size: 12px;
`;

function NewGroupContainer({ openGroupModal }) {
  const { setNodeRef, isOver } = useDroppable({ id: ThemeOrganizationContainer.NEW });

  return (
    <Col>
      <StyledDiv>
        <StyledCard ref={setNodeRef} $isOver={isOver} data-cy="new-group-container">
          <Size12Text type="secondary">
            <InfoCircleOutlined /> Themes sorted most responses to fewest
          </Size12Text>
          <StyledRow align="middle" justify="center">
            <StyledButton
              type="link"
              onClick={() => openGroupModal()}
              $isOver={isOver}
              data-cy="create-group-button"
            >
              <Text>{`+ Click or drag\nto create a new group`}</Text>
            </StyledButton>
          </StyledRow>
        </StyledCard>
      </StyledDiv>
    </Col>
  );
}

NewGroupContainer.propTypes = {
  openGroupModal: PropTypes.func.isRequired,
};

export default NewGroupContainer;
