import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Flex, Row, Space, Tag, Typography, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import useParams from '../../../../../hooks/useParams';
import { suggestedThemeType } from '../../../../../types';
import CreateSuggestedThemeModal from './CreateSuggestedThemeModal';

const { Text } = Typography;

const StyledTag = styled(Tag)`
  color: rgba(0, 0, 0, 0.45);
  border: none;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
`;

const StyledSuggestionText = styled(Text)`
  font-size: 18px;
`;

function SuggestedThemesHeader({
  suggestedThemes,
  selectedSuggestedThemeId,
  setSelectedSuggestedThemeId,
  selectedResponseIds,
  createThemeFromSuggestedTheme,
  dismissSuggestedTheme,
}) {
  const { codeFrameId } = useParams();
  const [isCreateSuggestedThemeModalVisible, setCreateSuggestedThemeModalVisible] = useState(false);

  const currentSuggestedThemeIndex = useMemo(
    () => suggestedThemes.findIndex((st) => st.id === selectedSuggestedThemeId),
    [suggestedThemes, selectedSuggestedThemeId],
  );

  const handleDismissSuggestedTheme = async () => {
    try {
      await dismissSuggestedTheme(selectedSuggestedThemeId);
    } catch {
      message.error('Something went wrong dismissing the suggestion');
    }
  };

  return (
    <Flex vertical gap={16}>
      <Row justify="space-between" align="middle">
        <Button
          icon={<LeftOutlined />}
          disabled={currentSuggestedThemeIndex === 0}
          onClick={() =>
            setSelectedSuggestedThemeId(suggestedThemes[currentSuggestedThemeIndex - 1].id)
          }
          data-cy="prev-suggested-theme"
        />
        {`${currentSuggestedThemeIndex + 1} of ${suggestedThemes.length} Groups`}
        <Button
          icon={<RightOutlined />}
          disabled={currentSuggestedThemeIndex === suggestedThemes.length - 1}
          onClick={() =>
            setSelectedSuggestedThemeId(suggestedThemes[currentSuggestedThemeIndex + 1].id)
          }
          data-cy="next-suggested-theme"
        />
      </Row>
      <Row justify="space-between">
        <Space>
          <StyledTag>AI-suggested</StyledTag>
          <StyledSuggestionText type="secondary">
            {suggestedThemes[currentSuggestedThemeIndex].name}
          </StyledSuggestionText>
        </Space>
        <Space>
          <Button onClick={handleDismissSuggestedTheme}>Dismiss all suggestions</Button>
          <Button
            onClick={() => setCreateSuggestedThemeModalVisible(true)}
            type="primary"
            disabled={!selectedResponseIds.length}
          >
            Tag {selectedResponseIds.length} suggestions
          </Button>
        </Space>
      </Row>
      <CreateSuggestedThemeModal
        visible={isCreateSuggestedThemeModalVisible}
        closeModal={() => setCreateSuggestedThemeModalVisible(false)}
        suggestedTheme={suggestedThemes[currentSuggestedThemeIndex]}
        createThemeFromSuggestedTheme={createThemeFromSuggestedTheme}
        codeFrameId={codeFrameId}
      />
    </Flex>
  );
}

SuggestedThemesHeader.propTypes = {
  suggestedThemes: PropTypes.arrayOf(suggestedThemeType).isRequired,
  selectedSuggestedThemeId: PropTypes.number.isRequired,
  setSelectedSuggestedThemeId: PropTypes.func.isRequired,
  selectedResponseIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  createThemeFromSuggestedTheme: PropTypes.func.isRequired,
  dismissSuggestedTheme: PropTypes.func.isRequired,
};

export default SuggestedThemesHeader;
