import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Drawer, Flex, Space, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useResponseOptions } from '../../../api/Questions';
import { useCreateOrder, useUpdateOrder } from '../../../api/Responses';
import Loading from '../../../components/Loading';
import { questionType } from '../../../types';
import ResponseOption from './ResponseOption';
import SortableResponseOption from './SortableResponseOption';

function FilteringQuestionDrawer({ question = null, setSelectedFilteringQuestion, surveyId }) {
  const [sortableItems, setSortableItems] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [saving, setSaving] = useState(false);

  const queryClient = useQueryClient();
  const createOrder = useMutation(useCreateOrder());
  const updateOrder = useMutation(useUpdateOrder());

  const { data: responseOptions, isFetching: responseOptionsFetching } = useQuery(
    ['responseOptions', { questionId: question?.id, surveyId }],
    useResponseOptions(),
    {
      enabled: !!question,
      onSuccess: (response) => setSortableItems(response.data.map((o) => o.text)),
    },
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(MouseSensor), // required for testing
  );

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveItem(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setSortableItems((curItems) => {
        const oldIndex = curItems.indexOf(active.id);
        const newIndex = curItems.indexOf(over.id);

        return arrayMove(curItems, oldIndex, newIndex);
      });
    }

    setActiveItem(null);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const newOptions = await Promise.all(
        sortableItems.map(async (item, index) => {
          const responseOption = responseOptions.data.find((option) => option.text === item);
          if (responseOption.order_id !== null) {
            await updateOrder.mutateAsync({
              orderId: responseOption.order_id,
              data: { order: index },
            });
            return { ...responseOption, response_order: index };
          }
          const result = await createOrder.mutateAsync({
            data: {
              question_id: question.id,
              text: item,
              order: index,
            },
          });
          return {
            text: item,
            order_id: result.data.order_id,
            response_order: index,
          };
        }),
      );
      queryClient.setQueryData(
        ['responseOptions', { questionId: question.id, surveyId }],
        (prevOptions) => ({ ...prevOptions, data: newOptions }),
      );
      message.success('Response options successfully updated');
    } catch {
      message.error('Something went wrong updating response options');
    } finally {
      setSaving(false);
    }
  };

  return (
    <Drawer
      open={!!question}
      closable={false}
      onClose={() => setSelectedFilteringQuestion(null)}
      title={question?.text}
      footer={
        <Space>
          <Button onClick={() => setSelectedFilteringQuestion(null)} disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave} type="primary" loading={saving} disabled={saving}>
            Save
          </Button>
        </Space>
      }
    >
      {/* most of this code comes from https://docs.dndkit.com/presets/sortable#drag-overlay */}
      {responseOptionsFetching || !sortableItems ? (
        <Loading />
      ) : (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext items={sortableItems} strategy={verticalListSortingStrategy}>
            <Flex vertical gap={8}>
              {sortableItems.map((item) => (
                <SortableResponseOption key={item} text={item} />
              ))}
            </Flex>
          </SortableContext>
          <DragOverlay modifiers={[restrictToWindowEdges]}>
            {activeItem && <ResponseOption text={activeItem} />}
          </DragOverlay>
        </DndContext>
      )}
    </Drawer>
  );
}

FilteringQuestionDrawer.propTypes = {
  question: questionType,
  setSelectedFilteringQuestion: PropTypes.func.isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default FilteringQuestionDrawer;
