import { Flex, Typography } from 'antd';
import React from 'react';
import { GridLoader } from 'react-spinners';
import styled from 'styled-components';
import { BluePrimary } from '../../styles';

const { Text, Title } = Typography;

const StyledFlex = styled(Flex)`
  margin-top: calc(100vh * 0.175);
`;

function SetupLoading() {
  return (
    <StyledFlex vertical align="center" gap={25}>
      <GridLoader color={BluePrimary} />
      <Flex vertical align="center" gap={1}>
        <Title level={3}>We&apos;re setting up your account</Title>
        <Text type="secondary">Your account is being created and will be ready soon.</Text>
        <Text type="secondary">Please do not close this window.</Text>
      </Flex>
    </StyledFlex>
  );
}

export default SetupLoading;
