import { InboxOutlined, InfoCircleOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, Row, Select, Space, Typography, Upload, message } from 'antd';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { QuestionTypeValues, SurveyTools, SurveyTypes } from '../../constants';
import SurveyUploadImage from '../../resources/survey-upload.svg';
import { BluePrimary } from '../../styles';
import { surveyDetailsType } from '../../types/index';

const { TextArea } = Input;
const { Option } = Select;
const { Text, Title, Link } = Typography;
const { Dragger } = Upload;

const StyledInboxOutlined = styled(InboxOutlined)`
  display: block;
  color: ${BluePrimary};
  font-size: 48px;
  margin-bottom: 1rem;
`;

const StyledText = styled(Text)`
  color: ${BluePrimary};
  font-size: 13px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${BluePrimary};
`;

const StyledUploadText = styled(Text)`
  font-size: 16px;
  margin-bottom: 8px;
`;

function FileUploadForm({ setSurveyData, next, showConfirm, surveyData }) {
  const [file, setFile] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (surveyData?.surveyDetails) {
      form.setFieldsValue(surveyData.surveyDetails);
      setFile(surveyData.surveyDetails.file[0]);
    }
  }, [form, surveyData]);

  const onFinish = async (formValues) => {
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      try {
        const csv = Papa.parse(target.result);
        const parsedQuestions = csv.data[0];
        // create a map of question names to counts
        const questionCounter = parsedQuestions.reduce((acc, question) => {
          if (acc[question]) {
            acc[question] += 1;
          } else {
            acc[question] = 1;
          }
          return acc;
        }, {});
        // find the first question with count > 1
        const duplicate = Object.entries(questionCounter).find((q) => q[1] > 1);
        if (duplicate) {
          const [duplicateQuestion, duplicateCount] = duplicate;
          message.error({
            content: `
              You have ${duplicateCount} columns which are called "${duplicateQuestion}".
              All column headers must be unique.
              Please remove or rename the column(s).
            `,
            duration: 10,
            style: { maxWidth: '50%', margin: 'auto' },
          });
        } else {
          setSurveyData({
            surveyDetails: formValues,
            questions: parsedQuestions.map((column, index) => {
              // if the column's name contains "-id", "_id", or " id" (case insensitively) or ID (case sensitively)
              // assume it's a respondent id
              // else if the column's first 25 responses contain > 10 responses with a comma or pipe, assume it's a multi-select
              // else if the column's first 25 responses contain < 10 unique values, assume it's a single-select
              // else if those responses' avg length is < 10
              // or on average they contain > 50% numeric chars, assume it's metadata
              // else assume it's an open-ended question
              // these values are just provisional; user can modify them on the next step
              let columnType;
              // `slice(1)` returns elements 1...n
              const nonEmptyResponses = csv.data.slice(1).filter((row) => row[index] !== '');
              const first25Responses = [
                ...new Set(nonEmptyResponses.slice(0, 25).map((row) => row[index])),
              ];
              const totalResponses = nonEmptyResponses.length;
              const first5NonEmptyResponses = nonEmptyResponses
                .slice(0, 5)
                .map((row) => ({ response: row[index], rowIndex: csv.data.indexOf(row) }));

              if (column.match(/[\s\-_][iI][dD]|ID/)) {
                columnType = QuestionTypeValues.IS_KEY;
              } else if (first25Responses.filter((r) => /[|]/.test(r)).length > 10) {
                columnType = QuestionTypeValues.IS_MULTI_SELECT;
              } else if (first25Responses.length < 10) {
                columnType = QuestionTypeValues.IS_SINGLE_SELECT;
              } else if (
                first25Responses.join('').length / first25Responses.length < 10 ||
                first25Responses.join('').replace(/\D/g, '').length /
                  first25Responses.join('').length >
                  0.5
              ) {
                columnType = QuestionTypeValues.IS_METADATA;
              } else {
                columnType = QuestionTypeValues.HAS_THEMES;
              }
              return {
                // dnd-kit can't handle falsey keys like "0", so use strings
                index: index.toString(),
                name: column,
                updated_question_name: column,
                columnType,
                responseCount: totalResponses,
                popOverResponses: first5NonEmptyResponses,
              };
            }),
          });
          next();
        }
      } catch {
        message.error('Something went wrong reading csv');
      }
    };
    reader.readAsText(formValues.file[0]);
  };

  return (
    <>
      <Title level={3}>Import a survey</Title>
      <Form onFinish={onFinish} autoComplete="off" layout="vertical" form={form}>
        <Form.Item
          name="file"
          rules={[{ required: true, message: 'Please attach a CSV file.' }]}
          valuePropName="fileList"
          getValueFromEvent={(e) => [e.file]}
        >
          <Dragger
            accept=".csv"
            beforeUpload={() => false}
            onChange={(e) => setFile(e.file)}
            showUploadList={false}
          >
            <StyledInboxOutlined />
            <Space direction="vertical" size={2}>
              {file ? (
                <>
                  <Space>
                    <Text type="secondary">
                      <PaperClipOutlined />
                    </Text>
                    {file.name}
                  </Space>
                  <StyledText>Drag or choose a new file</StyledText>
                </>
              ) : (
                <>
                  <Text>Drag a CSV file to this area to upload</Text>
                  <StyledText>Or select a CSV file from your computer</StyledText>
                </>
              )}
            </Space>
          </Dragger>
        </Form.Item>
        <Form.Item>
          <Space size={16} align="top">
            <img src={SurveyUploadImage} height={100} alt="CSV" />
            <Flex vertical>
              <StyledUploadText strong>
                <Space>
                  <StyledInfoCircleOutlined />
                  How to prep your CSV for upload
                </Space>
              </StyledUploadText>
              <Text>
                1. Make sure each <b>column</b> is a <i>question</i> or a <i>filter.</i>
              </Text>
              <Text>
                2. Make sure each <b>header</b> is a <i>label</i> for its column.
              </Text>
              <Text>
                3. Download handy{' '}
                <Link
                  href="https://docs.google.com/spreadsheets/d/1HhHPeleW5M1EjRZGN6lWJw-nqTUk543eLYOKz1cqdW4/edit#gid=1280319315"
                  target="_blank"
                  rel="noreferrer"
                >
                  template
                </Link>{' '}
                to see what your file should look like.
              </Text>
            </Flex>
          </Space>
        </Form.Item>
        <Form.Item
          label="Name"
          name="survey_title"
          rules={[{ required: true, message: 'Name is required.' }]}
        >
          <Input placeholder="How your survey will be labeled" />
        </Form.Item>
        <Form.Item label="Goal" name="survey_goals">
          <TextArea placeholder="Describe your survey's goals" rows={1} />
        </Form.Item>
        <Form.Item label="Type" name="survey_type">
          <Select allowClear placeholder="Select">
            {SurveyTypes.map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Survey fielding tool" name="survey_tool">
          <Select allowClear placeholder="Select">
            {SurveyTools.map((tool) => (
              <Option key={tool} value={tool}>
                {tool}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Space>
              <Button onClick={showConfirm}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Continue
              </Button>
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
}

FileUploadForm.propTypes = {
  setSurveyData: PropTypes.func.isRequired,
  surveyData: surveyDetailsType.isRequired,
  next: PropTypes.func.isRequired,
  showConfirm: PropTypes.func.isRequired,
};

export default FileUploadForm;
