import Icon, { EditOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Collapse, Space, Typography, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useUpdateThemeSummary } from '../../../api/CodeFrames';
import GenerateSummaryLoading from '../../../components/GenerateSummaryLoading';
import { MatomoEvent, SUMMARY_COLLAPSE_KEY } from '../../../constants';
import useMatomo from '../../../hooks/useMatomo';
import useParams from '../../../hooks/useParams';
import { ReactComponent as Wand } from '../../../resources/wand.svg';
import { questionType, summaryType } from '../../../types';
import EditThemeSummaryModal from './EditThemeSummaryModal';

const { Text } = Typography;

const StyledText = styled(Text)`
  font-size: 12px;
  white-space: pre-line;
`;

const StyledCollapse = styled(Collapse)`
  background: #ffffff;
  border: 1px solid #f0f0f0;

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-item {
    border: none;
  }
`;

function ThemeSummary({ themeId, question, summary }) {
  const { surveyId } = useParams();
  const { trackEvent } = useMatomo();
  const queryClient = useQueryClient();

  const [summaryCollapseKey, setSummaryCollapseKey] = useState(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [generateSummaryLoading, setGenerateSummaryLoading] = useState(false);

  const { mutate: updateSummary } = useMutation(useUpdateThemeSummary());

  const handleGenerateSummary = () => {
    setGenerateSummaryLoading(true);
    trackEvent(MatomoEvent.GENERATE_THEME_SUMMARY);
    setSummaryCollapseKey(SUMMARY_COLLAPSE_KEY);
    updateSummary(
      {
        codeFrameId: question.code_frame_id,
        themeId,
        surveyId,
        data: { summary_generated: true },
      },
      {
        onSuccess: () => {
          // wait 5 seconds before setting loading to false to show animation
          setTimeout(() => {
            queryClient.setQueryData(
              ['themeSummary', { surveyId, codeFrameId: question.code_frame_id, themeId }],
              (prevSummary) => ({
                ...prevSummary,
                data: { ...prevSummary.data, summary_generated: true },
              }),
            );
            setGenerateSummaryLoading(false);
          }, 5000);
        },
        onError: () => {
          message.error('Something went wrong generating summary');
          setGenerateSummaryLoading(false);
        },
      },
    );
  };

  const collapseSummary = [
    {
      key: SUMMARY_COLLAPSE_KEY,
      label: (
        <Space>
          <Text strong>Theme summary</Text>
        </Space>
      ),
      children: generateSummaryLoading ? (
        <GenerateSummaryLoading />
      ) : (
        <StyledText type="secondary">
          {summary.summary_generated
            ? summary.summary
            : 'Click "Generate summary" to produce AI-generated summary of coded-data associated with this theme. Summaries are fully editable.'}
        </StyledText>
      ),
      extra: summary.summary_generated ? (
        <Button
          icon={<Icon component={EditOutlined} />}
          type="link"
          size="small"
          onClick={() => setEditModalVisible(true)}
        >
          Edit summary
        </Button>
      ) : (
        <Button
          icon={<Icon component={Wand} />}
          type="link"
          size="small"
          onClick={handleGenerateSummary}
          loading={generateSummaryLoading}
        >
          Generate summary
        </Button>
      ),
    },
  ];

  return (
    <div>
      <StyledCollapse
        collapsible="icon"
        activeKey={summaryCollapseKey}
        onChange={setSummaryCollapseKey}
        items={collapseSummary}
      />
      <EditThemeSummaryModal
        visible={isEditModalVisible}
        closeModal={() => setEditModalVisible(false)}
        expandSummaryCollapse={() => setSummaryCollapseKey(SUMMARY_COLLAPSE_KEY)}
        summary={summary.summary}
        codeFrameId={question.code_frame_id}
        themeId={themeId}
        surveyId={surveyId}
      />
    </div>
  );
}

ThemeSummary.propTypes = {
  themeId: PropTypes.number.isRequired,
  question: questionType.isRequired,
  summary: summaryType.isRequired,
};

export default ThemeSummary;
