import { UserAddOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledInviteButton = styled(Button)`
  margin-right: 8px;
`;

function InviteButton({ onClick }) {
  const { user } = useAuth0();

  return user.email_verified ? (
    <StyledInviteButton key="1" icon={<UserAddOutlined />} onClick={onClick} type="link">
      Invite members
    </StyledInviteButton>
  ) : (
    <Tooltip title="You must verify your email in order to invite members">
      <StyledInviteButton key="1" icon={<UserAddOutlined />} disabled>
        Invite members
      </StyledInviteButton>
    </Tooltip>
  );
}

InviteButton.propTypes = { onClick: PropTypes.func.isRequired };

export default InviteButton;
