import { useMutation } from '@tanstack/react-query';
import { Form, Input, Modal, Select, Typography, message, notification } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useCreateInvitation } from '../../api/Invitations';
import { UserRoleType } from '../../constants';

const { Option } = Select;
const { Text } = Typography;

const StyledForm = styled(Form)`
  padding: 12px 16px 0;
`;

function InviteMemberModal({ visible, closeModal, onInvite = null }) {
  const [form] = Form.useForm();
  const { mutate: createInvitation, isLoading: createInvitationLoading } =
    useMutation(useCreateInvitation());

  const handleClose = () => {
    form.resetFields();
    closeModal();
  };

  const successDescription = (email) => (
    <>
      Invitation was successfully sent to:
      <br />
      <Text type="secondary">{email}</Text>
    </>
  );

  const onFinish = (formValues) => {
    createInvitation(
      { data: formValues },
      {
        onSuccess: (response, data) => {
          if (onInvite) {
            onInvite(response, data);
          }
          handleClose();
          notification.success({
            message: 'Invitation Sent',
            description: successDescription(formValues.email),
            placement: 'top',
          });
        },
        onError: () => {
          message.error('Something went wrong inviting that user');
        },
      },
    );
  };

  return (
    <Modal
      title="Invite team members"
      open={visible}
      onOk={() => {
        form
          .validateFields()
          .then(onFinish)
          .catch(() => {});
      }}
      onCancel={handleClose}
      okText="Send invite"
      confirmLoading={createInvitationLoading}
      maskClosable={false}
    >
      <StyledForm
        layout="horizontal"
        form={form}
        autoComplete="off"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <Input placeholder="Enter a name" />
        </Form.Item>
        <Form.Item
          label="Email address"
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please enter a valid email address',
            },
          ]}
        >
          <Input placeholder="Enter an email address" />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          initialValue={UserRoleType.VIEWER}
          rules={[{ required: true }]}
        >
          <Select>
            <Option value={UserRoleType.VIEWER}>{UserRoleType.VIEWER}</Option>
            <Option value={UserRoleType.EDITOR}>{UserRoleType.EDITOR}</Option>
            <Option value={UserRoleType.ADMINISTRATOR}>{UserRoleType.ADMINISTRATOR}</Option>
          </Select>
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

InviteMemberModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onInvite: PropTypes.func,
};

export default InviteMemberModal;
