import { InfoCircleOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Card, Flex, Tag, Tooltip, Typography, message } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useUpdateSurveySummary } from '../../../../api/Surveys';
import GenerateSummaryLoading from '../../../../components/GenerateSummaryLoading';
import { MatomoEvent, UserRoleType } from '../../../../constants';
import useMatomo from '../../../../hooks/useMatomo';
import useParams from '../../../../hooks/useParams';
import useRoles from '../../../../hooks/useRoles';
import { summaryType } from '../../../../types';
import EditSurveySummaryModal from './EditSurveySummaryModal';

const { Title, Text } = Typography;

const StyledTitle = styled(Title)`
  margin-top: 8px;
`;

const StyledText = styled(Text)`
  white-space: pre-line;
`;

function SurveySummary({ summary }) {
  const { surveyId } = useParams();
  const { trackEvent } = useMatomo();
  const queryClient = useQueryClient();
  const roles = useRoles();

  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [generateSummaryLoading, setGenerateSummaryLoading] = useState(false);

  const { mutate: updateSurveySummary } = useMutation(useUpdateSurveySummary());

  const handleGenerateSummary = () => {
    setGenerateSummaryLoading(true);
    trackEvent(MatomoEvent.GENERATE_SURVEY_SUMMARY);
    updateSurveySummary(
      { surveyId, data: { summary_generated: true } },
      {
        onSuccess: () => {
          // wait 5 seconds before setting loading to false to show animation
          setTimeout(() => {
            queryClient.setQueryData(['surveySummary', { surveyId }], (prevSummary) => ({
              ...prevSummary,
              data: { ...prevSummary.data, summary_generated: true },
            }));
            setGenerateSummaryLoading(false);
          }, 5000);
        },
        onError: () => {
          message.error('Something went wrong generating summary');
          setGenerateSummaryLoading(false);
        },
      },
    );
  };

  const cardContent = () => {
    if (generateSummaryLoading) {
      return <GenerateSummaryLoading />;
    }
    if (summary.summary_generated) {
      return <StyledText>{summary.summary}</StyledText>;
    }
    return (
      <Text type="secondary">
        Click &quot;Generate summary&quot; to produce AI-summarization and insights, based on the
        entire coded data set. These summaries and insights are fully editable
      </Text>
    );
  };

  return (
    <>
      <Flex justify="space-between" align="baseline" gap={8}>
        <Flex align="baseline" gap={8}>
          <StyledTitle level={4}>Survey insights</StyledTitle>
          <Tooltip title="Summary is generated by an AI model based on questions, themes, and responses">
            <Tag icon={<InfoCircleOutlined />}>Beta feature</Tag>
          </Tooltip>
        </Flex>
        {(roles.includes(UserRoleType.ADMINISTRATOR) || roles.includes(UserRoleType.EDITOR)) &&
          (summary.summary_generated ? (
            <Button size="small" onClick={() => setEditModalVisible(true)}>
              Edit summary
            </Button>
          ) : (
            <Button size="small" onClick={handleGenerateSummary} loading={generateSummaryLoading}>
              Generate summary
            </Button>
          ))}
      </Flex>
      <Card>{cardContent()}</Card>
      <EditSurveySummaryModal
        visible={isEditModalVisible}
        closeModal={() => setEditModalVisible(false)}
        summary={summary.summary}
        surveyId={surveyId}
      />
    </>
  );
}

SurveySummary.propTypes = {
  summary: summaryType.isRequired,
};

export default SurveySummary;
