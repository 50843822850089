import { InfoCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Card, Col, Divider, Row, Select, Space, Statistic, Tag, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSegmentSummaryTheme } from '../../api/CodeFrames';
import { useCodeFrames } from '../../api/Questions';
import Loading from '../../components/Loading';
import useParams from '../../hooks/useParams';
import { questionType, segmentSummaryType } from '../../types';

const { Title, Text, Paragraph, Link } = Typography;

const StyledDiv = styled.div`
  padding: 16px 24px;
  height: 100%;
  overflow: auto;
`;

const StyledTitle = styled(Title)`
  margin-top: 0;
`;

const StyledDivider = styled(Divider)`
  margin-top: 16px;
  margin-bottom: 16px;
  border-width: 1.5px;
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 12px;
  white-space: pre-line;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 12px;
`;

const StyledText = styled(Text)`
  font-size: 12px;
`;

const StyledStatistic = styled(Statistic)`
  .ant-statistic-title {
    font-size: 12px;
    color: black;
  }

  .ant-statistic-content {
    font-size: 20px;
  }
`;

const MIN_CRAMERS_LEVEL = 1;
const MIN_ODDS_RATIO_DIFF = 0.1;

function SegmentsSummaryPanel({
  question,
  segmentQuestion,
  summary = null,
  loading,
  responseOptions = [],
}) {
  const { surveyId } = useParams();
  const [selectedThemeId, setSelectedThemeId] = useState(undefined);

  useEffect(() => {
    setSelectedThemeId(undefined);
  }, [question]);

  const { data: themes, isLoading: themesLoading } = useQuery(
    ['summaryThemes', { questionIds: question.id, filters: { only_final: true }, surveyId }],
    useCodeFrames(),
    {
      select: (data) =>
        data.data[0].theme_json.themes
          .flatMap((t) => [t, ...(t.children || [])])
          .map((t) => ({ id: t.data.id, name: t.data.customer_theme_name || t.data.name })),
      enabled: !!summary,
    },
  );

  const { data: segmentSummaryTheme, isFetching: segmentSummaryThemeFetching } = useQuery(
    [
      'segmentSummaryTheme',
      {
        codeFrameId: question.code_frame_id,
        segmentId: segmentQuestion.id,
        themeId: selectedThemeId,
        surveyId,
      },
    ],
    useSegmentSummaryTheme(),
    {
      enabled: !!selectedThemeId,
    },
  );

  const sortResponseOptions = (a, b) => {
    const segmentA = responseOptions.find((opt) => a.segment_name === opt.text);
    const segmentB = responseOptions.find((opt) => b.segment_name === opt.text);
    return responseOptions.indexOf(segmentA) - responseOptions.indexOf(segmentB);
  };

  const options = themes
    ?.reduce(
      (acc, { name, id }) => {
        const group = summary?.theme_ids.includes(id) ? acc[0].options : acc[1].options;
        group.push({ label: name, value: id });
        return acc;
      },
      [
        { label: 'May contain meaningful variation', options: [] },
        { label: 'No meaningful variation', options: [] },
      ],
    )
    .filter((group) => group.options.length);

  const highlightExplanation = () => {
    const metrics = [];
    if (segmentSummaryTheme?.data.stats.cramers_v_level < MIN_CRAMERS_LEVEL) {
      metrics.push("Cramer's V");
    }
    if (segmentSummaryTheme?.data.stats.odds_ratio_diff < MIN_ODDS_RATIO_DIFF) {
      metrics.push('Odds Ratio');
    }
    if (!metrics.length) {
      return false;
    }
    return (
      <StyledText strong>Does not meet minimum threshold for: {metrics.join(', ')}</StyledText>
    );
  };

  if (loading || themesLoading) {
    return <Loading />;
  }

  return (
    <StyledDiv>
      <Space align="baseline">
        <StyledTitle level={4}>Stat Assist for &quot;{segmentQuestion.text}&quot;</StyledTitle>
        <Tooltip title="AI summary of statistical analysis of the variation of themes by segment">
          <Tag icon={<InfoCircleOutlined />}>Beta feature</Tag>
        </Tooltip>
      </Space>
      <StyledDivider />
      <StyledTitle level={5}>Overview</StyledTitle>
      <StyledParagraph>{summary?.initial_explanation}</StyledParagraph>
      <StyledParagraph>{summary?.main_insight}</StyledParagraph>
      <StyledTitle level={5}>Summary by Segment</StyledTitle>
      {summary?.option_summaries.sort(sortResponseOptions).map((option) => (
        <React.Fragment key={option.segment_name}>
          <Text strong>{option.segment_name}</Text>
          {option.response_count && <Text strong>{` (${option.response_count})`}</Text>}
          <StyledParagraph>{option.analysis}</StyledParagraph>
        </React.Fragment>
      ))}
      <StyledDivider />
      <StyledTitle level={5}>Detail by Individual Theme</StyledTitle>
      <StyledText type="secondary">Select an individual theme to further investigate</StyledText>
      <StyledSelect
        placeholder="Please select a theme"
        value={selectedThemeId}
        onChange={setSelectedThemeId}
        loading={segmentSummaryThemeFetching}
        options={options}
        showSearch
        optionFilterProp="label"
      />
      {segmentSummaryTheme && (
        <>
          <StyledParagraph>{segmentSummaryTheme?.data.main_insight}</StyledParagraph>
          <StyledParagraph>{segmentSummaryTheme?.data.metric_explanation}</StyledParagraph>
          {highlightExplanation()}
          <Row gutter={8} wrap={false}>
            <Col flex={1}>
              <Card size="small">
                <StyledStatistic
                  title="Prevalence"
                  value={segmentSummaryTheme?.data.stats.support}
                  precision={2}
                />
              </Card>
            </Col>
            <Col flex={1}>
              <Card size="small">
                <StyledStatistic
                  title="Cramer's V"
                  value={segmentSummaryTheme?.data.stats.cramers_v}
                  precision={2}
                />
              </Card>
            </Col>
            <Col flex={1}>
              <Card size="small">
                <StyledStatistic
                  title="Odds Ratio"
                  value={segmentSummaryTheme?.data.stats.odds_ratio_diff}
                  precision={2}
                />
              </Card>
            </Col>
          </Row>
        </>
      )}
      <StyledDivider />
      <Link
        href="https://intercom.help/fathomthat/en/articles/10041333-stat-assist-for-segments"
        target="_blank"
        rel="noreferrer noopener"
      >
        How Stat Assist works
      </Link>
    </StyledDiv>
  );
}

SegmentsSummaryPanel.propTypes = {
  question: questionType.isRequired,
  segmentQuestion: questionType.isRequired,
  summary: segmentSummaryType,
  loading: PropTypes.bool.isRequired,
  responseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      order_id: PropTypes.number,
      response_order: PropTypes.number,
      text: PropTypes.string,
    }),
  ),
};

export default SegmentsSummaryPanel;
