import { Flex, Select, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { questionType } from '../../types';

const { Title } = Typography;
const { Option } = Select;

const StyledTitle = styled(Title)`
  margin-top: 8px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

function OpenEndedQuestionsSelect({
  openEndedQuestions,
  selectedQuestionId = undefined,
  setSelectedQuestionId,
  extra = null,
}) {
  return (
    <div>
      <Flex justify="space-between" align="baseline">
        <StyledTitle level={5}>Select an open-ended question</StyledTitle>
        <Flex gap={8}>{extra}</Flex>
      </Flex>
      <StyledSelect
        defaultValue={selectedQuestionId}
        value={selectedQuestionId}
        onChange={setSelectedQuestionId}
      >
        {openEndedQuestions.map((q) => (
          <Option key={q.id} value={q.id}>
            {q.text}
          </Option>
        ))}
      </StyledSelect>
    </div>
  );
}

OpenEndedQuestionsSelect.propTypes = {
  openEndedQuestions: PropTypes.arrayOf(questionType).isRequired,
  selectedQuestionId: PropTypes.number,
  setSelectedQuestionId: PropTypes.func.isRequired,
  extra: PropTypes.arrayOf(PropTypes.element),
};

export default OpenEndedQuestionsSelect;
