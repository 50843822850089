import { Col, Flex, Input, Row, Table } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const { Search } = Input;

function SearchableTable({
  baseData = undefined,
  searchPlaceholder = 'Search...',
  onSearch = null,
  afterSearch = null,
  sortComponents = null,
  searchValue = '',
  className = '',
  searchColumns = [],
  advancedSearchComponents = null,
  ...props
}) {
  const [filteredData, setFilteredData] = useState(baseData);
  const [searchTerm, setSearchTerm] = useState(searchValue);

  useEffect(() => {
    if (baseData) {
      const filterData = searchColumns.length
        ? baseData?.filter((item) =>
            searchColumns.some((column) =>
              item[column].toLowerCase().includes(searchTerm.toLowerCase()),
            ),
          )
        : baseData;
      setFilteredData(filterData);
    }
  }, [baseData, searchTerm, searchColumns]);

  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      if (onSearch) {
        onSearch(searchTerm);
      }
      if (afterSearch) {
        afterSearch(searchTerm);
      }
    }, 300);
    return () => clearTimeout(debouncedSearch);
  }, [searchTerm, onSearch, afterSearch]);

  return (
    <Flex vertical className={className} gap={8}>
      <Row align="middle">
        <Col flex="auto">
          <Search
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={searchPlaceholder}
            allowClear
          />
        </Col>
        {advancedSearchComponents && <Col>{advancedSearchComponents.toggle}</Col>}
        <Col>{sortComponents}</Col>
      </Row>
      {advancedSearchComponents?.collapse}
      <Table
        dataSource={filteredData}
        // we want to be able to use ant design Table props
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Flex>
  );
}

SearchableTable.propTypes = {
  // baseData will be different depending on objects in table
  // eslint-disable-next-line react/forbid-prop-types
  baseData: PropTypes.arrayOf(PropTypes.object),
  searchPlaceholder: PropTypes.string,
  onSearch: PropTypes.func,
  afterSearch: PropTypes.func,
  sortComponents: PropTypes.element,
  searchValue: PropTypes.string,
  // We need className so we can create a styled SearchableTable
  className: PropTypes.string,
  searchColumns: PropTypes.arrayOf(PropTypes.string),
  advancedSearchComponents: PropTypes.objectOf(PropTypes.element),
};

export default SearchableTable;
