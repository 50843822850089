import { Button, Checkbox, Col, Modal, Row, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const { Text } = Typography;

function DeleteWarningModal({
  isModalOpen,
  setIsModalOpen,
  setHasDismissedDeleteWarning,
  handleDragEnd,
  groupName = '',
}) {
  return (
    <Modal
      key="delete-warning-modal"
      title="Delete Group"
      open={isModalOpen}
      maskClosable={false}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      footer={
        <Row justify="space-between">
          <Col>
            <Checkbox onChange={(e) => setHasDismissedDeleteWarning(e.target.checked)}>
              Don&apos;t show this warning again
            </Checkbox>
          </Col>
          <Col>
            <Space>
              <Button key="cancel" onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
              <Button
                key="delete"
                type="primary"
                onClick={() => {
                  handleDragEnd();
                  setIsModalOpen(false);
                }}
                danger
              >
                Delete
              </Button>
            </Space>
          </Col>
        </Row>
      }
    >
      <Text key="warning-text">
        <b> Are you sure you want to move this theme?</b> The group <i>{groupName}</i> will be
        deleted. To get this group back, you&apos;ll need to create it again.
      </Text>
    </Modal>
  );
}

DeleteWarningModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  setHasDismissedDeleteWarning: PropTypes.func.isRequired,
  handleDragEnd: PropTypes.func.isRequired,
  groupName: PropTypes.string,
};

export default DeleteWarningModal;
