import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { MatomoEvent } from '../../../constants';
import useMatomo from '../../../hooks/useMatomo';
import { queryType, questionType } from '../../../types';
import CreateSegmentModal from '../CreateSegmentModal';
import EditSegmentModal from '../EditSegmentModal';

const { Title, Text, Paragraph } = Typography;

const StyledTitle = styled(Title)`
  margin-top: 8px;
`;

const StyledTag = styled(Tag)`
  margin-top: 8px;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  color: ${(props) => (props.active ? '#fafafa' : '')};
`;

function SegmentResponsesCard({
  questions,
  questionResponseOptions,
  segments,
  toggleSegment,
  saveSegment,
  clearSegment,
  editSegmentName,
  removeSegment,
}) {
  const { trackEvent } = useMatomo();
  const [selectedSegment, setSelectedSegment] = useState(undefined);
  const [isEditSegmentModal, setIsEditSegmentModal] = useState(false);
  const [isCreateSegmentModal, setIsCreateSegmentModal] = useState(false);

  return (
    <div>
      <StyledTitle level={5}>Segment responses</StyledTitle>
      <Card size="small">
        <Row gutter={8} wrap={false}>
          <Col>
            <Paragraph>
              Explore responses by segment. Click &ldquo;Create segment&ldquo; to apply a
              demographic filter.
            </Paragraph>
            {segments.map((segment) => (
              <StyledTag key={segment.id} color={segment.active ? '#1890FF' : ''}>
                <Space>
                  <StyledText
                    active={segment.active ? 1 : 0}
                    onClick={() => toggleSegment(segment.id)}
                  >
                    {segment.text}
                  </StyledText>
                  <EditOutlined
                    onClick={() => {
                      setIsEditSegmentModal(true);
                      setSelectedSegment(segment);
                    }}
                  />
                </Space>
              </StyledTag>
            ))}
          </Col>
          <Col>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  trackEvent(MatomoEvent.ADD_BUTTON_IN_SEGMENTS_CLICKS);
                  setIsCreateSegmentModal(true);
                }}
              >
                Create segment
              </Button>
              <Button type="primary" onClick={clearSegment} danger disabled={segments.length === 1}>
                Clear all
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
      <EditSegmentModal
        visible={isEditSegmentModal}
        setVisible={setIsEditSegmentModal}
        segment={selectedSegment}
        editSegmentName={editSegmentName}
        removeSegment={removeSegment}
      />
      <CreateSegmentModal
        visible={isCreateSegmentModal}
        setVisible={setIsCreateSegmentModal}
        questions={questions}
        saveSegment={saveSegment}
        questionResponseOptions={questionResponseOptions}
      />
    </div>
  );
}

SegmentResponsesCard.propTypes = {
  questions: PropTypes.arrayOf(questionType).isRequired,
  questionResponseOptions: PropTypes.arrayOf(queryType).isRequired,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      active: PropTypes.bool,
      segmentType: PropTypes.string,
      segmentParams: PropTypes.string,
      newlyCreated: PropTypes.bool,
    }),
  ).isRequired,
  toggleSegment: PropTypes.func.isRequired,
  saveSegment: PropTypes.func.isRequired,
  clearSegment: PropTypes.func.isRequired,
  editSegmentName: PropTypes.func.isRequired,
  removeSegment: PropTypes.func.isRequired,
};

export default SegmentResponsesCard;
