import { Button, Col, Form, Input, Modal, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { SegmentType } from '../../constants';

function EditSegmentModal({ visible, setVisible, segment = {}, editSegmentName, removeSegment }) {
  const [form] = Form.useForm();
  return (
    <Modal
      title="Edit"
      open={visible}
      onCancel={() => setVisible(false)}
      destroyOnClose
      maskClosable={false}
      footer={[
        <Row justify="space-between" key={segment.id}>
          <Col>
            {(segment.segmentType ||
              (segment.segment_type && segment.segment_type !== SegmentType.ALL_RESPONDENTS)) && (
              <Button
                key="delete"
                onClick={() => {
                  removeSegment(segment.id);
                  setVisible(false);
                }}
                danger
                type="link"
              >
                Delete
              </Button>
            )}
          </Col>
          <Col>
            <Button type="secondary" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    editSegmentName(segment.id, values.name);
                    setVisible(false);
                  })
                  .catch(() => {});
              }}
            >
              Update
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Form layout="vertical" form={form} autoComplete="off" preserve={false}>
        <Form.Item
          label="Label"
          name="name"
          initialValue={segment.label || segment.text}
          rules={[{ required: true, message: 'name is required' }]}
        >
          <Input type="text" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

EditSegmentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  segment: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
    label: PropTypes.string,
    segmentType: PropTypes.string,
    segment_type: PropTypes.string,
  }),
  editSegmentName: PropTypes.func.isRequired,
  removeSegment: PropTypes.func.isRequired,
};

export default EditSegmentModal;
