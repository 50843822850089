import { Divider, Row, Switch, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { questionThemeType } from '../../types';

const { Text } = Typography;

const StyledDivider = styled(Divider)`
  margin: 16px 0;
`;

function ThemePicker({
  themes = [],
  switchTooltip,
  editable = false,
  handleEdit = () => {},
  deselectedThemes = [],
  onChange = () => {},
  allowSubthemesOnly = true,
}) {
  const selectedThemes = useMemo(
    () =>
      themes.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.data.id]: !deselectedThemes.includes(curr.data.id),
          ...curr.children.reduce(
            (a, c) => ({
              ...a,
              [c.data.id]: !deselectedThemes.includes(c.data.id),
            }),
            {},
          ),
        }),
        {},
      ),
    [themes, deselectedThemes],
  );

  const onThemeChange = (id, checked) => {
    let newThemes;
    if (checked) {
      newThemes = deselectedThemes.filter((tId) => tId !== id);
    } else {
      const theme = themes.find((t) => t.data.id === id);
      const subthemesIds = theme.children.map((c) => c.data.id);
      newThemes = [...deselectedThemes, id, ...subthemesIds];
    }
    onChange(newThemes);
  };

  const onSubthemeChange = (themeId, id, checked) => {
    let newThemes;
    if (checked) {
      newThemes = deselectedThemes.filter(
        (tId) => tId !== id && (allowSubthemesOnly || tId !== themeId),
      );
    } else {
      newThemes = [...deselectedThemes, id];
    }
    onChange(newThemes);
  };

  const getThemeLabel = (theme, type = 'default') => (
    <Text
      type={type}
      editable={
        editable
          ? {
              onChange: (e) => {
                handleEdit(theme, e);
              },
            }
          : false
      }
    >
      {theme.data.customer_theme_name || theme.data.name}
    </Text>
  );

  return (
    <>
      {themes?.map((t, idx) => (
        <React.Fragment key={t.data.id}>
          {idx !== 0 && <StyledDivider />}
          <Row justify="space-between" wrap={false} align="middle">
            {getThemeLabel(t)}
            <Tooltip title={switchTooltip} placement="right">
              <Switch
                size="small"
                checked={selectedThemes[t.data.id]}
                onChange={(checked) => onThemeChange(t.data.id, checked)}
              />
            </Tooltip>
          </Row>
          {t.children.map((c) => (
            <React.Fragment key={c.data.id}>
              <StyledDivider />
              <Row justify="space-between" wrap={false} align="middle">
                {getThemeLabel(c, 'secondary')}
                <Tooltip title={switchTooltip} placement="right">
                  <Switch
                    size="small"
                    checked={selectedThemes[c.data.id]}
                    onChange={(checked) => onSubthemeChange(t.data.id, c.data.id, checked)}
                  />
                </Tooltip>
              </Row>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </>
  );
}

ThemePicker.propTypes = {
  themes: PropTypes.arrayOf(questionThemeType),
  switchTooltip: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  handleEdit: PropTypes.func,
  deselectedThemes: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  allowSubthemesOnly: PropTypes.bool,
};

export default ThemePicker;
