import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useValidateInvitation } from '../../api/Invitations';
import Loading from '../../components/Loading';
import useParams from '../../hooks/useParams';
import InvalidInvitation from './InvalidInvitation';

function ValidateInvitation() {
  const { token } = useParams();
  const { loginWithRedirect } = useAuth0();

  const { mutate: validateInvitation, isError: invitationError } = useMutation({
    mutationFn: useValidateInvitation(),
    onSuccess: () => {
      loginWithRedirect({ authorizationParams: { screen_hint: 'signup', token } });
    },
  });

  useEffect(() => {
    validateInvitation({ token });
  }, [validateInvitation, token]);

  return invitationError ? <InvalidInvitation /> : <Loading />;
}

export default ValidateInvitation;
