import { Flex, Image, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import InvalidInvitationImage from '../../resources/invalid-invitation.svg';

const { Text, Title } = Typography;

const StyledFlex = styled(Flex)`
  margin-top: calc(100vh * 0.2);
`;

const StyledText = styled(Text)`
  text-align: center;
`;

function InvalidInvitation() {
  return (
    <StyledFlex vertical align="center">
      <Image src={InvalidInvitationImage} height={125} alt="invalid" />
      <Flex vertical align="center">
        <Title level={3}>Invalid invitation</Title>
        <StyledText type="secondary">
          Invitation is invalid, please contact your organization admin for a new invitation.
        </StyledText>
      </Flex>
    </StyledFlex>
  );
}

export default InvalidInvitation;
