import { EditOutlined, HolderOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useDraggable } from '@dnd-kit/core';
import { Button, Card, Col, Popover, Row, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const { Text, Title } = Typography;

const StyledSpace = styled(Space)`
  width: 350px;
`;

const StyledCard = styled(Card)`
  border-radius: 0px;

  .ant-card-body {
    padding: 6px 12px;
  }

  &:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:nth-last-child(1) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  & + & {
    border-top: none;
  }
`;

const StyledHolderOutlined = styled(HolderOutlined)`
  color: grey;
  font-size: 16px;
  margin-right: 8px;
  display: flex;

  &:hover {
    cursor: grab;
  }
`;

const StyledText = styled(Text)`
  display: flex !important;
  justify-content: space-between;
`;

const StyledTitle = styled(Title)`
  margin: 8px;
`;

function DraggableQuestion({ id, text = '', renameQuestion, responseCount, popOverResponses }) {
  const { attributes, listeners, setNodeRef } = useDraggable({ id });

  return (
    <StyledCard ref={setNodeRef} data-cy="question-card" size="small">
      <Row wrap={false} gutter={8} align="middle">
        <Col flex="none">
          <StyledHolderOutlined
            size="small"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...listeners}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attributes}
            data-cy="drag-icon"
          />
        </Col>
        <Col flex="auto">
          <StyledText
            ellipsis={{ tooltip: text }}
            editable={{
              icon: <EditOutlined size="small" />,
              onChange: (name) => {
                renameQuestion({
                  updatedName: name,
                  index: id,
                });
              },
            }}
          >
            {text}
          </StyledText>
        </Col>
        <Col flex="none">
          <Popover
            content={
              <StyledSpace direction="vertical">
                <Row>
                  {popOverResponses.map((res) => (
                    <React.Fragment key={res.rowIndex}>
                      <Col span={2} data-cy="response-idx">
                        <Text type="secondary">{`${res.rowIndex}.`}</Text>
                      </Col>
                      <Col span={22} data-cy="response-item">
                        {res.response}
                      </Col>
                    </React.Fragment>
                  ))}
                </Row>
                {responseCount > 5 && (
                  <Text type="secondary">{`${(responseCount - 5).toLocaleString()} more`}</Text>
                )}
              </StyledSpace>
            }
            title={<StyledTitle level={5}>Row Preview</StyledTitle>}
            trigger="click"
            placement="bottom"
          >
            <Button
              icon={<UnorderedListOutlined />}
              type="link"
              size="small"
              data-cy="popover-button"
            />
          </Popover>
        </Col>
      </Row>
    </StyledCard>
  );
}

DraggableQuestion.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  renameQuestion: PropTypes.func.isRequired,
  responseCount: PropTypes.number.isRequired,
  popOverResponses: PropTypes.arrayOf(
    PropTypes.shape({ response: PropTypes.string, rowIndex: PropTypes.number }),
  ).isRequired,
};

export default DraggableQuestion;
