import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const StyledBreadcrumb = styled(Breadcrumb)`
  padding-top: 16px;
  cursor: pointer;
`;

function BackButton({ onClick, text }) {
  return (
    <StyledBreadcrumb
      items={[
        {
          title: (
            <Space>
              <ArrowLeftOutlined />
              {text}
            </Space>
          ),
          onClick,
        },
      ]}
    />
  );
}

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default BackButton;
