import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Modal, Row, Space, Tag, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { suggestedThemeType } from '../../../../types';
import TagResponsesButton from './TagResponsesButton';

const { Text } = Typography;
const { confirm } = Modal;

const Size12Text = styled(Text)`
  font-size: 12px;
`;

const LinkSize12Text = styled(Size12Text)`
  color: #1890ff;
  cursor: pointer;
`;

const StyledCard = styled(Card)`
  border-color: ${(props) => (props.$isSelected ? '#69C0FF' : '#d9d9d9')};
  ${(props) => props.$isSelected && 'box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15)'};
  ${(props) => !props.$isSelected && 'cursor: pointer'};
  background: repeating-linear-gradient(-45deg, #f4f4f4, #f4f4f4 1px, #fff 1px, #fff 10px);

  .ant-card-body {
    padding: 8px 12px;
  }
`;

const StyledTag = styled(Tag)`
  color: rgba(0, 0, 0, 0.45);
  background: #f4f4f4;
  border: none;
`;

function SuggestedThemeCard({
  isSelected,
  suggestedTheme,
  selectedResponseIds,
  showSuggestedTheme,
  setSelectedThemeId,
  responseCount,
  createThemeFromSuggestedTheme,
  dismissSuggestedTheme,
}) {
  const [isLoading, setLoading] = useState(false);

  const confirmDismiss = () => {
    confirm({
      title: 'Are you sure you want to dismiss this AI-suggested theme?',
      content: 'This cannot be undone.',
      okText: 'Dismiss',
      okButtonProps: { danger: true, type: 'primary' },
      onOk: () => dismissSuggestedTheme(suggestedTheme.id),
    });
  };

  return (
    <StyledCard
      $isSelected={isSelected}
      onClick={() => setSelectedThemeId(`st${suggestedTheme.id}`)}
      data-cy="suggested-theme-card"
    >
      <Flex vertical gap={4}>
        <Row justify="space-between" wrap={false}>
          <Space align="start" size={0}>
            <StyledTag>AI-suggested</StyledTag>
            <Text type="secondary">{suggestedTheme.name}</Text>
          </Space>
          <Tooltip title="Dismiss AI-suggested theme">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                confirmDismiss();
              }}
              type="link"
              size="small"
              icon={<CloseCircleOutlined />}
            />
          </Tooltip>
        </Row>
        <Row justify="space-between" wrap={false}>
          <Size12Text>
            {`${suggestedTheme.response_ids.length} | ${Math.round(
              (suggestedTheme.response_ids.length / responseCount) * 100,
            )}%`}
          </Size12Text>
          <LinkSize12Text
            onClick={(e) => {
              e.stopPropagation();
              showSuggestedTheme(suggestedTheme.id);
            }}
          >
            See suggestions
          </LinkSize12Text>
        </Row>
        {isSelected && (
          <TagResponsesButton
            onClick={async () => {
              setLoading(true);
              await createThemeFromSuggestedTheme(suggestedTheme);
              setLoading(false);
            }}
            numResponses={selectedResponseIds.length}
            loading={isLoading}
          />
        )}
      </Flex>
    </StyledCard>
  );
}

SuggestedThemeCard.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  suggestedTheme: suggestedThemeType.isRequired,
  selectedResponseIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  showSuggestedTheme: PropTypes.func.isRequired,
  setSelectedThemeId: PropTypes.func.isRequired,
  responseCount: PropTypes.number.isRequired,
  createThemeFromSuggestedTheme: PropTypes.func.isRequired,
  dismissSuggestedTheme: PropTypes.func.isRequired,
};

export default SuggestedThemeCard;
