import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Flex, Modal, Row, Space, Statistic, Typography } from 'antd';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCodeFrames, useCreateCodeFrames } from '../../api/Questions';
import useParams from '../../hooks/useParams';
import SurveyDebriefImage from '../../resources/survey-debrief-image.svg';
import { BlueTitle, LightGray } from '../../styles';
import { questionType, surveyType } from '../../types';

dayjs.extend(localizedFormat);
const { Title, Text } = Typography;

const StyledCard = styled(Card)`
  background-color: ${LightGray};
`;

const StyledQuestionText = styled.h2`
  color: ${BlueTitle};
  font-weight: bold;
  margin: 0;
`;

const StyledStatistic = styled(Statistic)`
  text-align: center;
  .ant-statistic-title {
    font-weight: 500;
    color: black;
  }
  .ant-statistic-content {
    font-size: 14px;
  }
`;

function SurveyDebriefModal({ question = null, closeModal, survey }) {
  const navigate = useNavigate();
  const { surveyId } = useParams();

  const { mutate: createCodeFrames, isLoading: isCreateCFLoading } =
    useMutation(useCreateCodeFrames());

  const { refetch: fetchCodeFrame, isRefechting: isFetchingCodeFrame } = useQuery(
    ['codeFrameResponse', { questionIds: question?.id, surveyId }],
    useCodeFrames(),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.data.length) {
          navigate(
            `/surveys/${surveyId}/questions/${question.id}/codeframes/${data.data[0].id}/themeEngine/`,
          );
        } else {
          createCodeFrames(
            { questionId: question.id },
            {
              onSuccess: (response) => {
                navigate(
                  `/surveys/${surveyId}/questions/${question.id}/codeframes/${response.data.code_frame_id}/themeEngine/`,
                );
              },
            },
          );
        }
      },
    },
  );

  return (
    <Modal
      centered
      open={question}
      closable={false}
      width={1000}
      footer={null}
      onCancel={closeModal}
    >
      <Flex vertical gap={8}>
        <Card>
          <Space size={32}>
            <img src={SurveyDebriefImage} width={187} height={166} alt="Survey Debrief" />
            <div>
              <Title level={3}>Before diving in...</Title>
              <Text>
                To ground your analysis, refresh your memory on these survey details. Once
                you&apos;re ready, start analyzing.
              </Text>
            </div>
          </Space>
        </Card>
        <StyledCard bordered={false}>
          <Text type="secondary">Analyzing responses to</Text>
          <StyledQuestionText>{question?.text}</StyledQuestionText>
        </StyledCard>
        <Card bordered={false}>
          <Row justify="space-around">
            <StyledStatistic
              title="Survey type"
              value={`${survey.data.survey_type || 'Not provided'}`}
            />
            <StyledStatistic title="Responses" value={`${question?.num_responses}`} />
            <StyledStatistic
              title="Upload date"
              value={dayjs(survey.data.created_at).format('ll')}
            />
          </Row>
        </Card>
        <Button
          type="primary"
          onClick={fetchCodeFrame}
          loading={isFetchingCodeFrame || isCreateCFLoading}
          block
        >
          Let&apos;s go
        </Button>
      </Flex>
    </Modal>
  );
}

SurveyDebriefModal.propTypes = {
  question: questionType,
  closeModal: PropTypes.func.isRequired,
  survey: surveyType.isRequired,
};

export default SurveyDebriefModal;
