import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Input, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useUpdateSurveySummary } from '../../../../api/Surveys';
import { MatomoEvent } from '../../../../constants';
import useMatomo from '../../../../hooks/useMatomo';

const { TextArea } = Input;

function EditSurveySummaryModal({ visible, closeModal, summary, surveyId }) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { trackEvent } = useMatomo();
  const { mutate: updateSurveySummary, isLoading: updateSurveySummaryLoading } =
    useMutation(useUpdateSurveySummary());

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({ summary });
    }
  }, [form, summary, visible]);

  const onFinish = (values) => {
    trackEvent(MatomoEvent.EDIT_SURVEY_SUMMARY);
    updateSurveySummary(
      {
        surveyId,
        data: { summary: values.summary },
      },
      {
        onSuccess: () => {
          queryClient.setQueryData(['surveySummary', { surveyId }], (prevSummary) => ({
            ...prevSummary,
            data: { ...prevSummary.data, summary: values.summary },
          }));
          message.success('Survey summary updated');
        },
        onError: () => {
          message.error('Something went wrong updating survey summary');
        },
        onSettled: () => {
          closeModal();
        },
      },
    );
  };

  return (
    <Modal
      title="Edit survey summary"
      open={visible}
      width={600}
      onOk={() => {
        form
          .validateFields()
          .then(onFinish)
          .catch(() => {});
      }}
      okText="Update"
      onCancel={closeModal}
      confirmLoading={updateSurveySummaryLoading}
      maskClosable={false}
    >
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item
          name="summary"
          rules={[
            { required: true, message: 'Survey summary is required' },
            { whitespace: true, message: 'Survey summary cannot be blank' },
          ]}
        >
          <TextArea rows="6" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

EditSurveySummaryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  summary: PropTypes.string.isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default EditSurveySummaryModal;
