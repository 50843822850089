import { FrownOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

const { Title } = Typography;

const StyledFlex = styled(Flex)`
  margin-top: calc(100vh * 0.175);
`;

const StyledFrownOutlined = styled(FrownOutlined)`
  color: rgba(0, 0, 0, 0.45);
  font-size: 70px;
`;

export default function NotFound() {
  return (
    <StyledFlex vertical align="center" gap={16}>
      <StyledFrownOutlined />
      <Title level={2} type="secondary">
        Page not found
      </Title>
    </StyledFlex>
  );
}
