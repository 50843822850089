import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import { MATOMO_URL } from './constants';

const instance = createInstance({
  urlBase: MATOMO_URL,
  siteId: process.env.REACT_APP_MATOMO_SITE_ID,
});

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Router>
    <Auth0ProviderWithHistory>
      <MatomoProvider value={instance}>
        <ConfigProvider>
          <App />
        </ConfigProvider>
      </MatomoProvider>
    </Auth0ProviderWithHistory>
  </Router>,
);
