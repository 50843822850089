import { Flex, Image, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SummaryLoadingPhrases } from '../../constants';
import LoadingImage from '../../resources/generate-summary.svg';

const { Text } = Typography;

const StyledText = styled(Text)`
  font-size: 12px;
`;

function GenerateSummaryLoading() {
  const [loadingPhraseIndex, setLoadingPhraseIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingPhraseIndex((prevIndex) =>
        // reset index if current index is greater than array size
        prevIndex + 1 < SummaryLoadingPhrases.length ? prevIndex + 1 : 0,
      );
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <Flex vertical align="center" gap={8}>
      <Image src={LoadingImage} height={125} alt="invalid" />
      <StyledText type="secondary">{SummaryLoadingPhrases[loadingPhraseIndex]}</StyledText>
    </Flex>
  );
}

export default GenerateSummaryLoading;
